// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/user/code/oipm/annual-report-2018/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-allegations-analysis-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/allegations-analysis.js" /* webpackChunkName: "component---src-pages-allegations-analysis-js" */),
  "component---src-pages-complaints-analysis-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/complaints-analysis.js" /* webpackChunkName: "component---src-pages-complaints-analysis-js" */),
  "component---src-pages-complaints-anonymous-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/complaints-anonymous.js" /* webpackChunkName: "component---src-pages-complaints-anonymous-js" */),
  "component---src-pages-complaints-discipline-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/complaints-discipline.js" /* webpackChunkName: "component---src-pages-complaints-discipline-js" */),
  "component---src-pages-complaints-fourth-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/complaints-fourth.js" /* webpackChunkName: "component---src-pages-complaints-fourth-js" */),
  "component---src-pages-complaints-highlights-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/complaints-highlights.js" /* webpackChunkName: "component---src-pages-complaints-highlights-js" */),
  "component---src-pages-complaints-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/complaints.js" /* webpackChunkName: "component---src-pages-complaints-js" */),
  "component---src-pages-force-future-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/force-future.js" /* webpackChunkName: "component---src-pages-force-future-js" */),
  "component---src-pages-force-highlights-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/force-highlights.js" /* webpackChunkName: "component---src-pages-force-highlights-js" */),
  "component---src-pages-force-individual-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/force-individual.js" /* webpackChunkName: "component---src-pages-force-individual-js" */),
  "component---src-pages-force-justification-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/force-justification.js" /* webpackChunkName: "component---src-pages-force-justification-js" */),
  "component---src-pages-force-level-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/force-level.js" /* webpackChunkName: "component---src-pages-force-level-js" */),
  "component---src-pages-force-most-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/force-most.js" /* webpackChunkName: "component---src-pages-force-most-js" */),
  "component---src-pages-force-officer-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/force-officer.js" /* webpackChunkName: "component---src-pages-force-officer-js" */),
  "component---src-pages-force-outcome-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/force-outcome.js" /* webpackChunkName: "component---src-pages-force-outcome-js" */),
  "component---src-pages-force-time-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/force-time.js" /* webpackChunkName: "component---src-pages-force-time-js" */),
  "component---src-pages-force-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/force.js" /* webpackChunkName: "component---src-pages-force-js" */),
  "component---src-pages-index-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mediation-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/mediation.js" /* webpackChunkName: "component---src-pages-mediation-js" */),
  "component---src-pages-officers-js": () => import("/home/user/code/oipm/annual-report-2018/src/pages/officers.js" /* webpackChunkName: "component---src-pages-officers-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/user/code/oipm/annual-report-2018/.cache/data.json")

